import {
  Avatar,
  Blockquote,
  Box,
  Button,
  Card,
  Flex,
  Text,
} from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { formatCurrency } from "use-smart-locks-shared";
import {
  getApiUserErrorMessage,
  getMeteredCheckoutUrl,
} from "../../../shared/api/nest-api";
import { useUnit } from "../../hooks/use-unit";

export const UnitRentCheckout: React.FC = () => {
  const unit = useUnit();

  const mutation = useMutation({
    mutationFn: () => getMeteredCheckoutUrl(unit.id),
    onSuccess: (data) => {
      window.location.href = data.checkoutUrl;
    },
    onError: (error) => {
      const errorMessage = getApiUserErrorMessage(error);
      toast.error(
        errorMessage ?? "Leider ist ein unerwarteter Fehler aufgetreten.",
      );
    },
  });

  const handleBookClick = () => {
    mutation.mutate();
  };

  return (
    <Flex direction="column" gap="4">
      <Text>Jetzt ganz unkompliziert und sicher buchen.</Text>

      <Card>
        <Flex gap="3" align="center">
          <Avatar
            className="unit"
            size="3"
            src={unit.imageUrl}
            radius="large"
            fallback={unit.name.charAt(0)}
            style={{ objectFit: "cover" }}
          />
          <Box>
            <Text as="div" size="2" weight="bold">
              {unit.name}
            </Text>
            <Text as="div" size="2" color="gray">
              {unit.description}
            </Text>
          </Box>
        </Flex>
      </Card>

      <Text>Die Abrechnung erfolgt minutengenau mit günstigen:</Text>

      <Blockquote>
        {formatCurrency((unit.pricePerMinuteInCents ?? 0) / 100)} pro Minute
      </Blockquote>

      {unit.freeMinutes && (
        <Blockquote>
          Die ersten {unit.freeMinutes} Minuten sind sogar kostenlos!
        </Blockquote>
      )}

      <Text>
        Zur Buchung müssen Sie ein Zahlungsmittel hinterlegen. Wir belasten dies
        dann nach Rückgabe mit der minutengenauen Abrechnung.
      </Text>

      <Button
        type="button"
        onClick={handleBookClick}
        loading={mutation.isPending}
      >
        Jetzt buchen
      </Button>
    </Flex>
  );
};
