import {
  BleClient,
  BleDevice,
  RequestBleDeviceOptions,
  ScanResult,
} from "@capacitor-community/bluetooth-le";
import { isNativePlatform } from "../utils/environment";

export class BluetoothService {
  private static instance: BluetoothService | null = null;
  private isInitialized = false;

  private constructor() {}

  static getInstance(): BluetoothService {
    if (BluetoothService.instance === null) {
      BluetoothService.instance = new BluetoothService();
    }
    return BluetoothService.instance;
  }

  async initialize(): Promise<void> {
    if (!isNativePlatform()) {
      throw new Error(
        "Bluetooth ist nicht unterstützt. Bitte laden Sie die App herunter.",
      );
    }

    if (this.isInitialized) {
      // needs to be checked after initialization of the bluetooth client
      await this.assertBluetoothEnabled();
      return;
    }

    try {
      await BleClient.initialize({ androidNeverForLocation: true });
      this.isInitialized = true;
    } catch (error) {
      // an error might indicate that the device does not support bluetooth low energy:
      // https://github.com/capacitor-community/bluetooth-le?tab=readme-ov-file#initialize
      console.error("Error initializing BLE:", error);
      throw new Error(
        "Es gab ein Problem beim Herstellen der Bluetooth-Verbindung. Ist Bluetooth aktiviert?",
      );
    }

    // needs to be checked after initialization of the bluetooth client
    await this.assertBluetoothEnabled();
  }

  async assertBluetoothEnabled(): Promise<void> {
    try {
      const isBluetoothEnabled = await BleClient.isEnabled();
      if (!isBluetoothEnabled) {
        throw new Error();
      }
    } catch (error) {
      console.error("Error asserting Bluetooth enabled:", error);
      throw new Error("Bitte aktivieren Sie Bluetooth.");
    }
  }

  async requestDevice(options: RequestBleDeviceOptions): Promise<BleDevice> {
    try {
      const device = await BleClient.requestDevice(options);
      return device;
    } catch (error) {
      console.error("Error requesting device:", error);
      throw error;
    }
  }

  async connect(deviceId: string): Promise<void> {
    try {
      await BleClient.connect(deviceId, undefined, {
        // On Android, we got connection timeouts a few times on first connection attempt.
        // Let's see if we can avoid this by increasing the timeout (default is 10 seconds).
        timeout: 20_000,
      });
      console.log("Connected to device:", deviceId);
    } catch (error) {
      console.error("Error connecting to device:", error);
      throw error;
    }
  }

  async disconnect(deviceId: string): Promise<void> {
    try {
      await BleClient.disconnect(deviceId);
      console.log("Disconnected from device:", deviceId);
    } catch (error) {
      console.error("Error disconnecting from device:", error);
      throw error;
    }
  }

  async getConnectedDevices(services: string[]): Promise<BleDevice[]> {
    try {
      const devices = await BleClient.getConnectedDevices(services);
      return devices;
    } catch (error) {
      console.error("Error getting connected devices:", error);
      throw error;
    }
  }

  async startScanning(
    options: RequestBleDeviceOptions,
    callback: (result: ScanResult) => void,
  ): Promise<void> {
    try {
      await BleClient.requestLEScan(options, callback);
    } catch (error) {
      console.error("Error starting scan:", error);
      throw error;
    }
  }

  async stopScanning(): Promise<void> {
    try {
      await BleClient.stopLEScan();
      console.log("Scanning stopped");
    } catch (error) {
      console.error("Error stopping scan:", error);
      throw error;
    }
  }

  async readCharacteristic(
    deviceId: string,
    serviceUuid: string,
    characteristicUuid: string,
  ): Promise<DataView> {
    try {
      return await BleClient.read(deviceId, serviceUuid, characteristicUuid);
    } catch (error) {
      console.error("Error reading characteristic:", error);
      throw error;
    }
  }

  async writeCharacteristic(
    deviceId: string,
    serviceUuid: string,
    characteristicUuid: string,
    value: DataView,
  ): Promise<void> {
    try {
      await BleClient.write(deviceId, serviceUuid, characteristicUuid, value);
    } catch (error) {
      console.error("Error writing characteristic:", error);
      throw error;
    }
  }

  async startNotifications(
    deviceId: string,
    serviceUuid: string,
    characteristicUuid: string,
    callback: (value: DataView) => void,
  ): Promise<void> {
    try {
      await BleClient.startNotifications(
        deviceId,
        serviceUuid,
        characteristicUuid,
        callback,
      );
    } catch (error) {
      console.error("Error starting notifications:", error);
      throw error;
    }
  }

  async stopNotifications(
    deviceId: string,
    serviceUuid: string,
    characteristicUuid: string,
  ): Promise<void> {
    try {
      await BleClient.stopNotifications(
        deviceId,
        serviceUuid,
        characteristicUuid,
      );
    } catch (error) {
      console.error("Error stopping notifications:", error);
      throw error;
    }
  }
}

export default BluetoothService.getInstance();
