import {
  UseSuspenseQueryResult,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { Unit } from "use-smart-locks-shared";
import { getUnitBySlug } from "../../shared/api/nest-api";

export const useUnitQuery = (unitSlug: string): UseSuspenseQueryResult<Unit> =>
  useSuspenseQuery({
    queryKey: ["unit", unitSlug],
    queryFn: () => getUnitBySlug(unitSlug),
  });
