import { SplashScreen } from "@capacitor/splash-screen";
import { ErrorBoundary } from "@sentry/react";
import { PropsWithChildren } from "react";
import { UnexpectedError } from "./shared/components/unexpected-error";
import { useDeepLinking } from "./shared/deep-linking";
import { useTrackPageView } from "./shared/utils/posthog";

export const MainRoot: React.FC<PropsWithChildren> = ({ children }) => {
  useTrackPageView();
  useDeepLinking();
  SplashScreen.hide().catch(console.error);

  return (
    <ErrorBoundary fallback={<UnexpectedError />}>{children}</ErrorBoundary>
  );
};
