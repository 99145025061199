import {
  UseSuspenseQueryResult,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { Booking } from "use-smart-locks-shared";
import { queryClient } from "../api";
import { getBookingBySlug } from "../api/nest-api";

const queryOptions = (bookingSlug: string) => ({
  queryKey: ["booking", bookingSlug],
  queryFn: () => getBookingBySlug(bookingSlug),
});

export const useBookingQuery = (
  bookingSlug: string,
): UseSuspenseQueryResult<Booking> =>
  useSuspenseQuery(queryOptions(bookingSlug));

export const prefetchBookingQuery = async (
  bookingSlug: string,
): Promise<Booking | undefined> => {
  const options = queryOptions(bookingSlug);

  await queryClient.prefetchQuery(options);

  return await queryClient.getQueryData(options.queryKey);
};
