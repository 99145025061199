import {
  UseSuspenseQueryResult,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { MeteredQuote } from "use-smart-locks-shared";
import { getMeteredQuote } from "../../shared/api/nest-api";

const queryOptions = (unitId: string) => ({
  queryKey: ["unit", unitId, "quote"],
  queryFn: () => getMeteredQuote(unitId),
});

export const useMeteredQuoteQuery = (
  unitId: string,
): UseSuspenseQueryResult<MeteredQuote> =>
  useSuspenseQuery(queryOptions(unitId));
