import "./shared/utils/sentry.ts";

import {
  ChakraBaseProvider,
  theme as chakraTheme,
  extendBaseTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import RecoilizeDebugger from "recoilize";
import { UsersPage } from "./admin/users-page.component.tsx";
import AppPage from "./app-page.component.tsx";
import { AppRoot } from "./app-root.component.tsx";
import { Profile } from "./authentication/components/profile.component.tsx";
import { RequiresAuthentication } from "./authentication/components/requires-authentication.component.tsx";
import { LoginPage } from "./authentication/login-page.component.tsx";
import { BookingsForwardToStationPage } from "./bookings/bookings-forward-to-station-page.tsx";
import { BookingsRoot } from "./bookings/bookings-root.tsx";
import CampingBookingRoot from "./camping/camping-booking-root.tsx";
import { CampingRentRoot } from "./camping/camping-rent-root.tsx";
import { CampingRoot } from "./camping/camping-root.component.tsx";
import { CampingBookingSuccessPage } from "./camping/pages/camping-booking-success-page.tsx";
import CampingBookingUnlockPage from "./camping/pages/camping-booking-unlock-page.tsx";
import { CampingRentBikeSelectionPage } from "./camping/pages/camping-rent-bike-selection-page.js";
import { CampingRentCapturePage } from "./camping/pages/camping-rent-capture-page.tsx";
import { CampingRentPage } from "./camping/pages/camping-rent-page/camping-rent-page.component.tsx";
import "./index.css";
import { MainRoot } from "./main-root.tsx";
import { queryClient } from "./shared/api";
import { NotFound } from "./shared/components/not-found.tsx";
import {
  isNativeIOSPlatform,
  isSafariBrowser,
} from "./shared/utils/environment.ts";
import { posthogConfig } from "./shared/utils/posthog.ts";
import { UnitRentCapturePage } from "./units/pages/unit-rent-capture-page.tsx";
import { UnitRentPage } from "./units/pages/unit-rent-page/index.tsx";
import { UnitRoot } from "./units/unit-root.component.tsx";
import { UnlockStationPageComponent } from "./unlock/unlock-station-page.component.tsx";

const root = document.getElementById("root");
if (!root) throw new Error("No root element found");

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <MainRoot>
        <Outlet />
      </MainRoot>
    ),
    children: [
      {
        index: true,
        element: (
          <AppRoot>
            <Profile />
            <AppPage />
          </AppRoot>
        ),
      },
      {
        path: "/stations/:stationId/unlock",
        element: (
          <AppRoot>
            <Profile />
            <UnlockStationPageComponent />
          </AppRoot>
        ),
      },
      {
        path: "/stations/:stationSlug",
        element: (
          <CampingRoot>
            <Outlet />
          </CampingRoot>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="rent" replace />,
          },
          {
            path: "rent",
            element: (
              <CampingRentRoot>
                <Outlet />
              </CampingRentRoot>
            ),
            children: [
              {
                index: true,
                element: <CampingRentPage />,
              },
              {
                path: "bikes",
                element: <CampingRentBikeSelectionPage />,
              },
              {
                path: "capture",
                element: <CampingRentCapturePage />,
              },
            ],
          },
          {
            path: "bookings/:bookingSlug",
            element: (
              <CampingBookingRoot>
                <Outlet />
              </CampingBookingRoot>
            ),
            children: [
              {
                index: true,
                element: <CampingBookingUnlockPage />,
              },
              {
                path: "success",
                element: <CampingBookingSuccessPage />,
              },
            ],
          },
        ],
      },
      {
        path: "/units/:unitSlug",
        element: (
          <UnitRoot>
            <Outlet />
          </UnitRoot>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="rent" replace />,
          },
          {
            path: "rent",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <UnitRentPage />,
              },
              {
                path: "capture",
                element: <UnitRentCapturePage />,
              },
            ],
          },
        ],
      },
      {
        path: "/authentication/login",
        element: (
          <AppRoot>
            <Profile />
            <LoginPage />
          </AppRoot>
        ),
      },
      {
        path: "/bookings/:bookingSlug",
        element: (
          <BookingsRoot>
            <BookingsForwardToStationPage />
          </BookingsRoot>
        ),
      },
      {
        path: "admin",
        element: (
          <AppRoot>
            <Profile />
            <RequiresAuthentication>
              <Outlet />
            </RequiresAuthentication>
          </AppRoot>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="users" replace={true} />,
          },
          {
            path: "users",
            element: <UsersPage />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const {
  Button,
  Input,
  FormError,
  FormLabel,
  Alert,
  Heading,
  Spinner,
  Table,
  Modal,
  Tabs,
  Link,
  Tooltip,
  CloseButton,
} = chakraTheme.components;

const theme = extendBaseTheme(
  {
    components: {
      Button,
      Input,
      FormError,
      FormLabel,
      Alert,
      Heading,
      Spinner,
      Table,
      Modal,
      Tabs,
      Link,
      Tooltip,
      CloseButton,
    },
    styles: {
      global: {
        "#root": {
          minHeight: "100vh",
          textAlign: "left", // overwriting fixes e.g. error message alignment
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: "blue" }),
);

const isIOSOrSafari = isNativeIOSPlatform() || isSafariBrowser();

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <RecoilRoot>
      {/* On Safari and iOS, the debugger might cause issues e.g. the page breaks
      when choosing a value in a dropdown on the rent page. `Error: Snapshot has already been
      released.` */}
      {import.meta.env.DEV && !isIOSOrSafari && <RecoilizeDebugger />}
      <QueryClientProvider client={queryClient}>
        <ChakraBaseProvider theme={theme}>
          <Theme>
            <PostHogProvider {...posthogConfig}>
              <RouterProvider router={router} />

              <ToastContainer theme="colored" />

              {/* <ThemePanel /> */}
            </PostHogProvider>
          </Theme>
        </ChakraBaseProvider>
      </QueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>,
);
