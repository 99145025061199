// we use aes-js because window.crypto doesn't support the ECB mode
import * as aesjs from "aes-js";

export const encryptData = async (
  key: Uint8Array,
  data: Uint8Array,
): Promise<Uint8Array> => {
  const aesEcb = new aesjs.ModeOfOperation.ecb(key);
  const encryptedBytes = aesEcb.encrypt(data);
  return Promise.resolve(encryptedBytes);
};

export const decryptData = async (
  key: Uint8Array,
  data: Uint8Array,
): Promise<Uint8Array> => {
  const aesEcb = new aesjs.ModeOfOperation.ecb(key);
  const decryptedBytes = aesEcb.decrypt(data);
  return Promise.resolve(decryptedBytes);
};
