import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Callout, Flex, Text } from "@radix-ui/themes";

export const UnitRentUnavailable: React.FC = () => {
  return (
    <Flex gap="4" direction="column">
      <Callout.Root color="red">
        <Callout.Icon>
          <ExclamationTriangleIcon />
        </Callout.Icon>
        <Callout.Text>Leider bereits gebucht!</Callout.Text>
      </Callout.Root>

      <Text style={{ textAlign: "justify" }}>
        Falls es Ihre Buchung ist, klicken Sie bitte auf den Link in der
        Buchungsbestätigungs E-Mail. Mit diesem können Sie das Rad aufschließen,
        zurückgeben und die Buchung verwalten.
      </Text>
    </Flex>
  );
};
