import {
  ChevronLeftIcon,
  HamburgerMenuIcon,
  OpenInNewWindowIcon,
  PlusCircledIcon,
} from "@radix-ui/react-icons";
import {
  Box,
  DropdownMenu,
  Flex,
  Heading,
  IconButton,
  Link,
  Separator,
} from "@radix-ui/themes";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import useAppLogo from "../../assets/use-app-icon.png";
import { env } from "../../env";
import { isNativeIOSPlatform } from "../../shared/utils/environment";

type HeaderWithMenuProps = {
  title: string;
  homePath: string;
  menuItems: { name: string; path: string }[];
};

export const HeaderWithMenu: React.FC<HeaderWithMenuProps> = ({
  homePath,
  title,
  menuItems,
}) => {
  const navigate = useNavigate();
  const isNativeIOS = isNativeIOSPlatform();

  return (
    <Box width="100%">
      <Flex direction="row" gap="2" align="center">
        {isNativeIOS && (
          <IconButton
            color="gray"
            variant="ghost"
            onClick={() => {
              void navigate(-1);
            }}
            style={{ flexShrink: "0" }}
          >
            <ChevronLeftIcon width="18" height="18" />
          </IconButton>
        )}
        <RouterLink to={homePath} style={{ flexShrink: "0" }}>
          <img src={useAppLogo} className="shared-logo-small" alt="Use logo" />
        </RouterLink>

        <Box flexGrow="1" minWidth="0">
          <RouterLink to={homePath}>
            <Heading as="h1" truncate>
              {title}
            </Heading>
          </RouterLink>
        </Box>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <IconButton color="gray" variant="outline">
              <HamburgerMenuIcon width="18" height="18" />
            </IconButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {/* We had to use custom styles for the menu items because using 'asChild' produced errors when opening the menu (#BRA-41). */}
            {menuItems.map((menuItem) => (
              <DropdownMenu.Item
                key={menuItem.path}
                className="dropdown-menu-item"
              >
                <RouterLink
                  to={menuItem.path}
                  className="dropdown-menu-item-content"
                >
                  <PlusCircledIcon />
                  <span>{menuItem.name}</span>
                </RouterLink>
              </DropdownMenu.Item>
            ))}

            <DropdownMenu.Item className="dropdown-menu-item">
              <Link
                href={env.contactPage}
                target="_blank"
                className="dropdown-menu-item-content"
              >
                <OpenInNewWindowIcon />
                <span>Kontakt</span>
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item className="dropdown-menu-item">
              <Link
                href="https://www.usegroup.eu/impressum"
                target="_blank"
                className="dropdown-menu-item-content"
              >
                <OpenInNewWindowIcon />
                <span>Impressum</span>
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item className="dropdown-menu-item">
              <Link
                href="https://www.usegroup.eu/datenschutz"
                target="_blank"
                className="dropdown-menu-item-content"
              >
                <OpenInNewWindowIcon />
                <span>Datenschutz</span>
              </Link>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Flex>

      <Box mt="2">
        <Separator size="4" />
      </Box>
    </Box>
  );
};
