import { CampingStation } from "use-smart-locks-shared";
import { useStationQuery } from "../queries/use-station-query";

export const useStation = (stationSlug: string): CampingStation => {
  const { data, isError } = useStationQuery(stationSlug);

  if (isError) {
    throw new Error("Loading station failed.");
  }

  return data;
};
