import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isWebPlatform } from "../utils/environment";
import { Sentry } from "../utils/sentry";
import { useGenericErrorHandler } from "./use-generic-error-handler";

const ALLOWED_HOSTNAMES = [
  "use.test.brainchimps.com",
  "use.staging.brainchimps.com",
  "app.usegroup.eu",
  "localhost",
];

export function useQRCodeScanner() {
  const navigate = useNavigate();
  const { showError } = useGenericErrorHandler();
  const [isLoading, setIsLoading] = useState(false);

  const showInvalidQRCodeError = (qrValue: string, errorDetails?: unknown) => {
    Sentry.captureEvent({
      message: "Invalid QR code scanned",
      level: "warning",
      extra: {
        qrValue,
        errorDetails,
      },
    });
    showError({
      title: "Ungültiger QR-Code",
      description:
        "Dieser QR-Code scheint nicht für unsere App gültig zu sein.",
    });
  };

  const parseAndNavigate = (url: string) => {
    try {
      const parsedUrl = new URL(url);

      if (!ALLOWED_HOSTNAMES.includes(parsedUrl.hostname)) {
        showInvalidQRCodeError(url);
        return;
      }

      void navigate(parsedUrl.pathname);
    } catch (error) {
      showInvalidQRCodeError(url, error);
    }
  };

  const scanQRCode = async () => {
    setIsLoading(true);
    try {
      const { CapacitorBarcodeScanner, CapacitorBarcodeScannerTypeHint } =
        await import("@capacitor/barcode-scanner");

      if (isWebPlatform()) {
        // On web, the scanBarcode promise does not settle if the user closes the popup.
        // As a workaround, we set the loading state to false after a short delay.
        setTimeout(() => setIsLoading(false), 2_000);
      }

      const result = await CapacitorBarcodeScanner.scanBarcode({
        hint: CapacitorBarcodeScannerTypeHint.QR_CODE,
      });

      parseAndNavigate(result.ScanResult);
    } finally {
      setIsLoading(false);
    }
  };

  return { scanQRCode, isLoading };
}
