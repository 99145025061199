import { useParams } from "react-router-dom";

export const useUnitSlug = (): string => {
  const { unitSlug } = useParams();

  if (!unitSlug) {
    throw new Error("unitSlug route parameter is required");
  }

  return unitSlug;
};
