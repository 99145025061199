import { generatePath } from "react-router-dom";

export const routes = {
  station: (stationId: string) =>
    generatePath("/stations/:stationId", { stationId }),

  camping: {
    index: (stationSlug: string) =>
      generatePath("/stations/:stationSlug", { stationSlug }),

    rent: {
      index: (stationSlug: string) =>
        generatePath("/stations/:stationSlug/rent", { stationSlug }),
      bikeSelection: (stationSlug: string) =>
        generatePath("/stations/:stationSlug/rent/bikes", { stationSlug }),
    },

    booking: {
      unlock: (stationSlug: string, bookingSlug: string) =>
        generatePath("/stations/:stationSlug/bookings/:bookingSlug", {
          stationSlug,
          bookingSlug,
        }),
      success: (stationSlug: string, bookingSlug: string) =>
        generatePath("/stations/:stationSlug/bookings/:bookingSlug/success", {
          stationSlug,
          bookingSlug,
        }),
    },
  },

  units: {
    index: (unitSlug: string) => generatePath("/units/:unitSlug", { unitSlug }),
    rent: {
      index: (unitSlug: string) =>
        generatePath("/units/:unitSlug/rent", { unitSlug }),
    },
  },
};
