import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Box, Callout, Code, Flex, Progress, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routes } from "../../routes";
import { captureMeteredCheckout } from "../../shared/api/nest-api";
import { ContactUsButton } from "../../shared/components/contact-us-button";
import { prefetchBookingQuery } from "../../shared/queries/use-booking-query";
import { createMutex } from "../../shared/utils/mutex";
import { useUnit } from "../hooks/use-unit";

const mutex = createMutex();

export const UnitRentCapturePage: React.FC = () => {
  const unit = useUnit();

  const [searchParams] = useSearchParams();
  const checkoutId = searchParams.get("checkout_id");
  if (!checkoutId) {
    throw new Error("checkout_id query parameter required");
  }

  const navigate = useNavigate();

  const [error, setError] = useState<unknown>();

  useEffect(() => {
    // avoid capturing the same checkout multiple times,
    // e.g. when component is rendered multiple times in react strict mode
    void mutex.runOnce(async () => {
      try {
        const result = await captureMeteredCheckout(unit.id, checkoutId);

        const booking = await prefetchBookingQuery(result.bookingSlug);
        if (!booking) {
          setError("Buchung nicht gefunden.");
          return;
        }

        void navigate(
          routes.camping.booking.unlock(
            booking.stationSlug,
            result.bookingSlug,
          ),
          {
            replace: true,
          },
        );
      } catch (error) {
        // display custom error message in this critical part of the app
        setError(error);
      }
    });
  }, [unit.id, checkoutId, navigate]);

  if (error) {
    return (
      <Flex direction="column" gap="4">
        <Callout.Root color="red">
          <Callout.Icon>
            <ExclamationTriangleIcon />
          </Callout.Icon>
          <Callout.Text>
            Es ist leider ein Fehler bei der Buchung aufgetreten!
          </Callout.Text>
        </Callout.Root>

        <div>
          Bitte kontaktieren Sie uns damit wir Ihnen schnell und unkompliziert
          helfen können.
        </div>

        <div>
          Nennen Sie uns dabei bitte Ihre Buchungsnummer:{" "}
          <Code>{checkoutId.substring(0, 15)}</Code>.
        </div>

        <ContactUsButton />
      </Flex>
    );
  }

  return (
    <Flex direction="column" align="center" gap="4">
      <Text>
        Bitte haben Sie einen kurzen Moment Geduld während wir Ihre Zahlung
        verarbeiten.
      </Text>

      <Box width="100%">
        <Progress duration="10s" size="3" />
      </Box>
    </Flex>
  );
};
