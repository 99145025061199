import axios, { isAxiosError } from "axios";
import {
  Booking,
  BookingEndResponse,
  CampingCaptureResponse,
  CampingCheckoutRequest,
  CampingCheckoutResponse,
  CampingQuote,
  CampingQuoteRequest,
  CampingStation,
  Interface,
  MeteredCheckoutCaptureResponse,
  MeteredCheckoutResponse,
  MeteredQuote,
  Unit,
  UnlockLocksOfBookingResponse,
} from "use-smart-locks-shared";
import { env } from "../../env";
import { queryClient } from "./query-client";

if (!env.nestApiUrl) {
  throw new Error("VITE_NEST_API_URL is not defined");
}
if (!env.nestUnlockBookingUrl) {
  throw new Error("VITE_NEST_UNLOCK_BOOKING_URL is not defined");
}

const api = axios.create({
  baseURL: env.nestApiUrl,
});

export const getApiUserErrorMessage = (error: unknown): string | undefined => {
  if (!isAxiosError(error)) {
    return undefined;
  }

  const data = error.response?.data as unknown;

  if (
    data &&
    typeof data === "object" &&
    "error" in data &&
    "message" in data
  ) {
    // ignore errors that only have a "message" but not an "error" field since those are defaults like "Bad Request"

    return data.message?.toString();
  }

  return undefined;
};

export const getCampingStationBySlug = (
  stationSlug: string,
): Promise<CampingStation> =>
  api
    .get<CampingStation>("/camping/stations/get-by-slug", {
      params: { slug: stationSlug },
    })
    .then((response) => response.data);

export const getCampingQuote = (
  stationId: string,
  data: Interface<CampingQuoteRequest>,
): Promise<CampingQuote> =>
  api
    .post<CampingQuote>(
      `/camping/stations/${encodeURIComponent(stationId)}/checkout/quote`,
      data,
    )
    .then((response) => response.data);

export const getCampingCheckoutUrl = (
  stationId: string,
  data: Interface<CampingCheckoutRequest>,
): Promise<CampingCheckoutResponse> =>
  api
    .post<CampingCheckoutResponse>(
      `/camping/stations/${encodeURIComponent(stationId)}/checkout`,
      data,
    )
    .then((response) => response.data);

export const captureCampingCheckout = (
  stationId: string,
  checkoutId: string,
): Promise<CampingCaptureResponse> =>
  api
    .post<CampingCaptureResponse>(
      `/camping/stations/${encodeURIComponent(stationId)}/checkout/${encodeURIComponent(checkoutId)}/capture`,
    )
    .then((response) => response.data);

export const getBookingBySlug = (bookingSlug: string): Promise<Booking> =>
  api
    .get<Booking>(`/bookings/get-by-slug`, {
      params: {
        slug: bookingSlug,
      },
    })
    .then((response) => response.data);

export const unlockLocksOfBooking = async (
  bookingId: string,
): Promise<UnlockLocksOfBookingResponse> => {
  /**
   * Use dedicated handler for unlocking locks because this is a lengthy process exceeding the API Gateway timeout and therefore directly invoked via Lambda URL.
   *
   * TODO: clean up and remove dedicated lambda function. We've increased the timeout of the API Gateway, so we can use the Nest API directly.
   */
  // if (import.meta.env.DEV) {
  //   await api
  //     .post<never>(`/bookings/${encodeURIComponent(bookingId)}/unlock`)
  //     .then((response) => response.data);
  // } else {
  //   await api.post(env.nestUnlockBookingUrl, {
  //     bookingId,
  //   });
  // }
  const result = await api
    .post<UnlockLocksOfBookingResponse>(
      `/bookings/${encodeURIComponent(bookingId)}/unlock`,
    )
    .then((response) => response.data);
  await invalidateBookings();
  return result;
};

export const endBooking = async (bookingId: string) => {
  const result = await api
    .post<BookingEndResponse>(`/bookings/${encodeURIComponent(bookingId)}/end`)
    .then((response) => response.data);
  await invalidateBookings();
  return result;
};

const invalidateBookings = async () => {
  /**
   * Make sure that e.g. startedAt date is updated.
   * We can invalidate specific bookings in the future (by passing the slug), but for now we just invalidate all.
   */
  await queryClient.invalidateQueries({
    queryKey: ["booking"],
  });
};

export const getUnitBySlug = (unitSlug: string): Promise<Unit> =>
  api
    .get<Unit>("/metered/units/get-by-slug", {
      params: { slug: unitSlug },
    })
    .then((response) => response.data);

export const getMeteredQuote = (unitId: string): Promise<MeteredQuote> =>
  api
    .get<MeteredQuote>(
      `/metered/units/${encodeURIComponent(unitId)}/checkout/quote`,
    )
    .then((response) => response.data);

export const getMeteredCheckoutUrl = (
  unitId: string,
): Promise<MeteredCheckoutResponse> =>
  api
    .post<MeteredCheckoutResponse>(
      `/metered/units/${encodeURIComponent(unitId)}/checkout`,
    )
    .then((response) => response.data);

export const captureMeteredCheckout = (
  unitId: string,
  checkoutId: string,
): Promise<MeteredCheckoutCaptureResponse> =>
  api
    .post<MeteredCheckoutCaptureResponse>(
      `/metered/units/${encodeURIComponent(unitId)}/checkout/${encodeURIComponent(checkoutId)}/capture`,
    )
    .then((response) => response.data);
