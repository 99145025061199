import {
  BleDevice,
  RequestBleDeviceOptions,
} from "@capacitor-community/bluetooth-le";
import { DeviceAuthenticationData } from "use-smart-locks-shared";
import bluetoothService from "./bluetooth.service";
import * as cryptoUtils from "./crypto.utils";

type DeviceState = {
  counter: number;
  key: Uint8Array | null;
  device: BleDevice | null;
  authenticationData: DeviceAuthenticationData | null;
};

// UUIDs (as described in the iLockit documentation attached in #BRA-34)
// const CHARACTERISTIC_NOTIFICATION_DESCRIPTOR = '00002902-0000-1000-8000-00805f9b34fb';
// const CHARACTERISTIC_BATTERY_LEVEL = "00002a19-0000-1000-8000-00805f9b34fb";
const SERVICE = "0000f00d-1212-efde-1523-785fef13d123";
// const SERVICE_BATTERY = "0000180F-0000-1000-8000-00805f9b34fb";
const CHARACTERISTIC_AUTHENTICATION = "0000baab-1212-efde-1523-785fef13d123";
const CHARACTERISTIC_LOCK_CONTROL = "0000beee-1212-efde-1523-785fef13d123";
const CHARACTERISTIC_LOCK_STATE = "0000baaa-1212-efde-1523-785fef13d123";
// const CHARACTERISTIC_ALARM = "0000bfff-1212-efde-1523-785fef13d123";
// const CHARACTERISTIC_ALARM_SETTINGS = "0000bffe-1212-efde-1523-785fef13d123";
// const CHARACTERISTIC_SOUND_SETTINGS = "0000baae-1212-efde-1523-785fef13d123";
// const CHARACTERISTIC_FIRMWARE_VERSION = "0000baad-1212-efde-1523-785fef13d123";

export enum LockState {
  Open = 0x00,
  Closed = 0x01,
  Unknown = 0x02,
  MovementPreventedClosing = 0x03,
  BoltBlockedPreventedOpening = 0x04,
  BoltBlockedPreventedClosing = 0x05,
}

class BluetoothIlockitService {
  private static instance: BluetoothIlockitService | null = null;

  private constructor() {}

  /** Maps device name to the current state. */
  private deviceState = new Map<string, DeviceState>();

  static getInstance(): BluetoothIlockitService {
    if (BluetoothIlockitService.instance === null) {
      BluetoothIlockitService.instance = new BluetoothIlockitService();
    }
    return BluetoothIlockitService.instance;
  }

  public updateDeviceAuthenticationData(
    deviceName: string,
    authenticationData: DeviceAuthenticationData,
  ) {
    const currentState = this.deviceState.get(deviceName);
    if (currentState?.authenticationData) {
      return;
    }
    this.updateDeviceState(deviceName, {
      counter: 1,
      key: null,
      device: null,
      authenticationData,
    });
  }

  public async openLock(deviceName: string): Promise<void> {
    await this.connectAndExecuteCommand(deviceName, 0x01);
  }

  public async closeLock(deviceName: string): Promise<void> {
    await this.connectAndExecuteCommand(deviceName, 0x02);
  }

  public async readLockStatus(deviceName: string): Promise<LockState> {
    // In thoery, it's not necessary that the lock is authenticated, it just needs to be connected.
    // For simplicity, we always use the same routine to connect and authenticate.
    const device = await this.connectToLock(deviceName);
    const value = await bluetoothService.readCharacteristic(
      device.deviceId,
      SERVICE,
      CHARACTERISTIC_LOCK_STATE,
    );
    return value.getUint8(0) as LockState;
  }

  private async connectToLock(deviceName: string): Promise<BleDevice> {
    try {
      await bluetoothService.initialize();

      const deviceIfConnected =
        await this.getDeviceIfConnectedAndAuthenticated(deviceName);
      if (deviceIfConnected) {
        return deviceIfConnected;
      }

      const device = await this.scanForDevices({ name: deviceName });
      if (!device) {
        throw new Error(
          "Fahrrad konnte nicht gefunden werden. Stehen Sie in der Nähe und haben Sie Bluetooth aktiviert?",
        );
      }

      await bluetoothService.connect(device.deviceId);

      this.updateDeviceState(deviceName, { counter: 1, device });

      await this.authenticate(device);

      return device;
    } catch (error) {
      console.error("Error connecting to lock:", error);
      throw error;
    }
  }

  /**
   * Executes a lock command, ensuring the device is connected and authenticated first.
   * If the device is already connected and authenticated, it will use the existing connection.
   * @param deviceName The name of the device to connect to
   * @param command The command to execute (e.g., 0x01 for open, 0x02 for close)
   */
  private async connectAndExecuteCommand(
    deviceName: string,
    command: number,
  ): Promise<void> {
    await this.connectToLock(deviceName);
    await this.executeLockCommand(deviceName, command);
  }

  private async executeLockCommand(deviceName: string, command: number) {
    const deviceState = this.deviceState.get(deviceName);
    if (!deviceState) {
      throw new Error("Device not connected");
    }

    const { device, key, counter } = deviceState;

    if (!device || !key) {
      throw new Error("Device not connected or not authenticated");
    }

    try {
      const commandPacket = this.createCommandPacket(counter, command);
      const encryptedCommand = await cryptoUtils.encryptData(
        key,
        commandPacket,
      );

      await bluetoothService.writeCharacteristic(
        device.deviceId,
        SERVICE,
        CHARACTERISTIC_LOCK_CONTROL,
        new DataView(encryptedCommand.buffer),
      );

      this.updateDeviceState(deviceName, { counter: counter + 1 });
    } catch (error) {
      console.error(
        `Error executing lock command (${command.toString()}):`,
        error,
      );
      throw error;
    }
  }

  private async authenticate(device: BleDevice): Promise<void> {
    const deviceName = device.name;
    if (!deviceName) {
      throw new Error("Device name is not set");
    }
    const authenticationData =
      this.deviceState.get(deviceName)?.authenticationData;
    if (!authenticationData) {
      throw new Error("No authentication data available");
    }

    const { seed, userKey } = authenticationData;
    const seedUint8Array = new Uint8Array(seed);
    const userKeyUint8Array = new Uint8Array(userKey);

    return new Promise<void>((resolve, reject) => {
      let isCleanedUp = false;

      // Consider authentication failed if we don't get a response within a certain timeframe.
      const timeoutId = setTimeout(() => {
        handleCleanup();
        // If the promise has already been resolved, this reject call will be ignored
        // as per the Promise specification (a Promise can only be settled once)
        reject(new Error("Authentication timed out"));
      }, 10_000);

      const handleCleanup = () => {
        if (isCleanedUp) return;

        isCleanedUp = true;
        clearTimeout(timeoutId);
        void bluetoothService.stopNotifications(
          device.deviceId,
          SERVICE,
          CHARACTERISTIC_AUTHENTICATION,
        );
      };

      const cleanUpAndResolve = () => {
        handleCleanup();
        resolve();
      };

      const cleanUpAndReject = (error: Error) => {
        handleCleanup();
        reject(error);
      };

      const authHandler = this.createAuthChallengeHandler(
        userKeyUint8Array,
        device,
        cleanUpAndResolve,
        cleanUpAndReject,
      );

      // Set up notification for auth response
      bluetoothService
        .startNotifications(
          device.deviceId,
          SERVICE,
          CHARACTERISTIC_AUTHENTICATION,
          authHandler,
        )
        .then(() =>
          // Send the seed to start the authentication process
          bluetoothService.writeCharacteristic(
            device.deviceId,
            SERVICE,
            CHARACTERISTIC_AUTHENTICATION,
            new DataView(seedUint8Array.buffer),
          ),
        )
        .catch(cleanUpAndReject);
    });
  }

  private createAuthChallengeHandler(
    userKey: Uint8Array,
    device: BleDevice,
    resolve: () => void,
    reject: (error: Error) => void,
  ) {
    const handle = async (value: DataView) => {
      try {
        const mockChallenge = new Uint8Array(value.buffer);
        const decryptedChallenge = await cryptoUtils.decryptData(
          userKey,
          mockChallenge,
        );

        const modifiedChallenge = new Uint8Array(decryptedChallenge);
        modifiedChallenge[modifiedChallenge.length - 1] += 1;
        const encryptedResponse = await cryptoUtils.encryptData(
          userKey,
          modifiedChallenge,
        );

        // Send modified challenge to confirm authentication
        await bluetoothService.writeCharacteristic(
          device.deviceId,
          SERVICE,
          CHARACTERISTIC_AUTHENTICATION,
          new DataView(encryptedResponse.buffer),
        );

        this.updateDeviceState(device.name ?? "", {
          counter: 1,
          key: userKey,
        });

        resolve();
      } catch (error) {
        console.error("Error during authentication:", error);
        reject(
          error instanceof Error ? error : new Error("Authentication failed"),
        );
      }
    };

    return (value: DataView) => void handle(value);
  }

  private async scanForDevices(
    options: RequestBleDeviceOptions,
  ): Promise<BleDevice | null> {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        console.log("Scan for devices: timeout");
        void bluetoothService.stopScanning();
        resolve(null);
      }, 10_000);

      bluetoothService
        .startScanning(options, (result) => {
          console.log("Scan for devices: found lock", result);
          void bluetoothService.stopScanning();
          clearTimeout(timeoutId);
          resolve(result.device);
        })
        .catch((error: unknown) => {
          clearTimeout(timeoutId);
          reject(
            new Error(
              error instanceof Error ? error.message : "Couldn't find lock.",
            ),
          );
        });
    });
  }

  private updateDeviceState(
    deviceName: string,
    update: Partial<DeviceState>,
  ): void {
    const currentState = this.deviceState.get(deviceName) ?? {
      counter: 1,
      key: null,
      device: null,
      authenticationData: null,
    };
    this.deviceState.set(deviceName, { ...currentState, ...update });
  }

  private createCommandPacket = (
    counter: number,
    command: number,
  ): Uint8Array => {
    // Create 16-byte array filled with zeros
    const result = new Uint8Array(16);

    // Set counter (LSB first, then MSB)
    result[0] = counter & 0xff; // LSB
    result[1] = (counter >> 8) & 0xff; // MSB

    // Set command byte
    result[2] = command;

    return result;
  };

  /**
   * Returns the device if it's connected via bluetooth and properly initialized.
   * The logic resets the state and disconnects the device if there is a mismatch.
   *
   * @param deviceName The name of the device to get the connected device for
   * @returns The connected device, or null
   */
  private async getDeviceIfConnectedAndAuthenticated(
    deviceName: string,
  ): Promise<BleDevice | null> {
    const deviceState = this.deviceState.get(deviceName);
    const isDeviceInitialized = deviceState?.device && deviceState.key;

    const connectedDevice =
      await this.getDeviceIfConnectedViaBluetooth(deviceName);

    if (isDeviceInitialized && connectedDevice) {
      return deviceState.device;
    }

    console.log(
      "Device not initialized or not connected, resetting state and connection...",
    );

    // Reset initialized state and disconnect device to have a consistent state.
    // Note that if a device is connected, it cannot be found via scanning.
    // Also, if we previously connected but have e.g. an incorrect counter, we need to trigger the authentication logic again.
    this.updateDeviceState(deviceName, {
      counter: 1,
      device: null,
      key: null,
    });
    if (connectedDevice) {
      await bluetoothService.disconnect(connectedDevice.deviceId);
    }
    return null;
  }

  private async getDeviceIfConnectedViaBluetooth(
    deviceName: string,
  ): Promise<BleDevice | null> {
    const connectedDevices = await bluetoothService.getConnectedDevices([
      SERVICE,
    ]);
    return (
      connectedDevices.find((device) => device.name === deviceName) ?? null
    );
  }
}

export const bluetoothIlockitService = BluetoothIlockitService.getInstance();
