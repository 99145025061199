import { routes } from "../../routes";
import { HeaderWithMenu } from "../../shared/components/header-with-menu";
import { useStation } from "../../shared/hooks/use-station";
import { useUnit } from "../hooks/use-unit";
import { useUnitSlug } from "../hooks/use-unit-slug";

export const UnitHeader: React.FC = () => {
  const unitSlug = useUnitSlug();
  const unit = useUnit();
  const station = useStation(unit.stationSlug);

  const homeRoute = routes.units.index(unitSlug);
  const newBookingRoute = routes.units.rent.index(unitSlug);

  return (
    <HeaderWithMenu
      title={station.name}
      homePath={homeRoute}
      menuItems={[{ name: "Neue Buchung", path: newBookingRoute }]}
    />
  );
};
