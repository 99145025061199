export const createMutex = () => {
  let mutexCounter = 0;

  return {
    runOnce: async (cb: () => Promise<void>) => {
      try {
        if (mutexCounter++ !== 0) return;

        await cb();
      } finally {
        mutexCounter--;
      }
    },
  };
};
