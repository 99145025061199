import { useToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

type ShowErrorParams = {
  title?: string;
  description: string;
};

export const useGenericErrorHandler = () => {
  const toast = useToast();

  const showError = ({ title, description }: ShowErrorParams) => {
    toast({
      title,
      description,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const handleError = (
    error: unknown,
    fallbackMessage = "Es ist ein unerwarteter Fehler aufgetreten",
  ) => {
    Sentry.captureException(error);

    if (error instanceof Error) {
      showError({
        description: error.message || fallbackMessage,
      });
    } else {
      showError({
        description: fallbackMessage,
      });
    }
  };

  return {
    handleError,
    showError,
  };
};
