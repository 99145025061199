export type RequirementType = "charging" | "keyreturn";
export const requirementTypes: RequirementType[] = ["charging", "keyreturn"];

export const RequirementStatus = {
  Fulfilled: "fulfilled",
  NotFulfilled: "notFulfilled",
  NotApplicable: "notApplicable",
  Error: "error",
} as const;

export type RequirementStatus =
  (typeof RequirementStatus)[keyof typeof RequirementStatus];

export type Requirement = {
  type: RequirementType;
  status: RequirementStatus;
  reason?: string;
};

export function isValidRequirementStatusToEndBooking(
  status: RequirementStatus,
) {
  return (
    status === RequirementStatus.Fulfilled ||
    status === RequirementStatus.NotApplicable
  );
}

export function createRequirementFactory(type: RequirementType) {
  return ({ status, reason }: Omit<Requirement, "type">) => ({
    type,
    status,
    reason,
  });
}

type UnitStatus = {
  id: string;
  requirements: Requirement[];
};

export type BookingEndResponse = {
  /** Booking id. */
  id: string;
  /**
   * True, if the booking has ended successfully.
   * False, if any of the requirements of any of the units is not fulfilled.
   */
  success: boolean;
  /**
   * List of units with their requirements and their fulfillment status.
   */
  unitStatuses: UnitStatus[];
};
