import {
  UseSuspenseQueryResult,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { CampingStation } from "use-smart-locks-shared";
import { getCampingStationBySlug } from "../api/nest-api";

export const useStationQuery = (
  stationSlug: string,
): UseSuspenseQueryResult<CampingStation> =>
  useSuspenseQuery({
    queryKey: ["station", stationSlug],
    queryFn: () => getCampingStationBySlug(stationSlug),
  });
