import { Flex } from "@radix-ui/themes";
import { useMeteredQuote } from "../../hooks/use-metered-quote";
import { useUnit } from "../../hooks/use-unit";
import { UnitRentCheckout } from "./unit-rent-checkout";
import { UnitRentUnavailable } from "./unit-rent-unavailable";

export const UnitRentPage: React.FC = () => {
  const unit = useUnit();
  const quote = useMeteredQuote(unit.id);

  return (
    <Flex gap="4" direction="column" align="center">
      {quote.available ? <UnitRentCheckout /> : <UnitRentUnavailable />}
    </Flex>
  );
};
