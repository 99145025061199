import { useState } from "react";
import { DeviceAuthenticationData } from "use-smart-locks-shared";
import {
  bluetoothIlockitService,
  LockState,
} from "./bluetooth-ilockit.service";

/**
 * Maximum time to wait for a lock operation (open/close) to complete.
 */
const LOCK_OPERATION_TIMEOUT_MS = 20_000;

/**
 * Interval between polling attempts to check lock status during operations.
 */
const LOCK_STATUS_POLL_INTERVAL_MS = 500;

type UseBluetoothLockReturn = {
  isLoading: boolean;
  updateDeviceAuthenticationData: (
    deviceName: string,
    authenticationData: DeviceAuthenticationData,
  ) => void;
  openLock: (lockName: string) => Promise<void>;
  closeLock: (lockName: string) => Promise<void>;
  readLockStatus: (lockName: string) => Promise<LockState>;
};

export const useBluetoothLock = (): UseBluetoothLockReturn => {
  const [isLoading, setIsLoading] = useState(false);

  const updateDeviceAuthenticationData = (
    deviceName: string,
    authenticationData: DeviceAuthenticationData,
  ) => {
    bluetoothIlockitService.updateDeviceAuthenticationData(
      deviceName,
      authenticationData,
    );
  };

  const openLock = async (lockName: string) => {
    setIsLoading(true);
    try {
      await bluetoothIlockitService.openLock(lockName);

      const startTime = Date.now();

      while (Date.now() - startTime < LOCK_OPERATION_TIMEOUT_MS) {
        const status = await bluetoothIlockitService.readLockStatus(lockName);

        switch (status) {
          case LockState.Open:
            return;
          case LockState.BoltBlockedPreventedOpening:
            throw new Error(
              "Schloss kann nicht geöffnet werden: Riegel blockiert",
            );
          default:
            await new Promise((resolve) =>
              setTimeout(resolve, LOCK_STATUS_POLL_INTERVAL_MS as number),
            );
            break;
        }
      }

      throw new Error(
        "Schloss konnte nicht geöffnet werden: Zeitüberschreitung",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const closeLock = async (lockName: string) => {
    setIsLoading(true);
    try {
      await bluetoothIlockitService.closeLock(lockName);

      const startTime = Date.now();

      while (Date.now() - startTime < LOCK_OPERATION_TIMEOUT_MS) {
        const status = await bluetoothIlockitService.readLockStatus(lockName);

        switch (status) {
          case LockState.Closed:
            return;
          case LockState.BoltBlockedPreventedClosing:
            throw new Error(
              "Schloss kann nicht geschlossen werden: Riegel blockiert",
            );
          case LockState.MovementPreventedClosing:
            throw new Error(
              "Schloss kann nicht geschlossen werden: Bewegung erkannt",
            );
          default:
            await new Promise((resolve) =>
              setTimeout(resolve, LOCK_STATUS_POLL_INTERVAL_MS as number),
            );
            break;
        }
      }

      throw new Error(
        "Schloss konnte nicht geschlossen werden: Zeitüberschreitung",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const readLockStatus = async (lockName: string) => {
    return bluetoothIlockitService.readLockStatus(lockName);
  };

  return {
    isLoading,
    updateDeviceAuthenticationData,
    openLock,
    closeLock,
    readLockStatus,
  };
};
