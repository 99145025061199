import { routes } from "../../routes";
import { HeaderWithMenu } from "../../shared/components/header-with-menu";
import { useCampingStation } from "../hooks/use-camping-station";
import { useCampingStationSlug } from "../hooks/use-camping-station-slug";

export const CampingHeader: React.FC = () => {
  const campingStation = useCampingStation();
  const campingStationSlug = useCampingStationSlug();
  const campingStationHomeRoute = routes.camping.index(campingStationSlug);
  const campingStationRentRoute = routes.camping.rent.index(campingStationSlug);

  return (
    <HeaderWithMenu
      title={campingStation.name}
      homePath={campingStationHomeRoute}
      menuItems={[{ name: "Neue Buchung", path: campingStationRentRoute }]}
    />
  );
};
