import { Unit } from "use-smart-locks-shared";
import { useUnitQuery } from "../queries/use-unit-query";
import { useUnitSlug } from "./use-unit-slug";

export const useUnit = (): Unit => {
  const unitSlug = useUnitSlug();

  const { data, isError } = useUnitQuery(unitSlug);

  if (isError) {
    throw new Error("Loading unit failed.");
  }

  return data;
};
