import { CheckCircledIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import {
  Avatar,
  Blockquote,
  Box,
  Button,
  Callout,
  Card,
  Flex,
  Strong,
  Text,
} from "@radix-ui/themes";
import { useEffect, useReducer, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  BOOKING_UNLOCK_GRACE_PERIOD_HOURS,
  BOOKING_UNLOCK_THRESHOLD_MINUTES,
  type Unit,
  UnlockLocksOfBookingResponse,
  formatDate,
  formatTime,
  formatTimeUntil,
  isAtMostNHoursAfter,
  isInFuture,
} from "use-smart-locks-shared";
import UnlockBikeIcon from "../../assets/unlock-bike-icon.svg?react";
import { getApiUserErrorMessage } from "../../shared/api/nest-api";
import { useBluetoothLock } from "../../shared/bluetooth/use-bluetooth-lock";
import { useGenericErrorHandler } from "../../shared/hooks/use-generic-error-handler";
import { useUnlockLocksOfBookingMutation } from "../commands/use-unlock-locks-of-booking-mutation";
import { useCampingBooking } from "../hooks/use-camping-booking";
import { EndBooking } from "./camping-booking-unlock-page/end-booking.component";

export default function CampingBookingUnlockPage() {
  const { booking, hasMultipleUnits, hasBluetoothLock } = useCampingBooking();

  const includesUnitLocks = booking.units.every((unit) => !unit.pin);
  const hasDoorLock = !booking.units.every((unit) => !unit.hasLock);
  const unlockResult = useRef<UnlockLocksOfBookingResponse | null>(null);
  const [isEndBookingInProgress, setIsEndBookingInProgress] = useState(false);

  const {
    mutate: unlock,
    mutateAsync: unlockAsync,
    isSuccess: isUnlockSuccess,
    status: unlockStatus,
  } = useUnlockLocksOfBookingMutation({
    onError(error) {
      toast.error(
        getApiUserErrorMessage(error) ??
          "Leider ist ein unerwarteter Fehler aufgetreten.",
      );
    },
  });

  const {
    openLock,
    closeLock,
    updateDeviceAuthenticationData,
    readLockStatus,
    isLoading: isLockOperationInProgress,
  } = useBluetoothLock();

  const startBookingAndUpdateAuthenticationData = async (unitId: string) => {
    if (!unlockResult.current) {
      const result = await unlockAsync(booking.id);
      unlockResult.current = result;
    }

    const authenticationData =
      unlockResult.current.unitIdsToBluetoothAuthentication[unitId];
    if (!authenticationData) {
      const error =
        "Authentifizierungsdaten für Schloss nicht verfügbar. Bitte Service-Hotline kontaktieren.";
      toast.error(error);
      throw new Error(error);
    }

    updateDeviceAuthenticationData(
      authenticationData.deviceName,
      authenticationData,
    );

    return authenticationData;
  };

  const getLockStatus = async (unitId: string) => {
    const { deviceName } =
      await startBookingAndUpdateAuthenticationData(unitId);
    return readLockStatus(deviceName);
  };

  const openBluetoothLock = async (unitId: string) => {
    const { deviceName } =
      await startBookingAndUpdateAuthenticationData(unitId);
    await openLock(deviceName);
  };

  const closeBluetoothLock = async (unitId: string) => {
    const { deviceName } =
      await startBookingAndUpdateAuthenticationData(unitId);
    await closeLock(deviceName);
  };

  const isUnlockAvailable = !isInFuture(
    booking.starts,
    BOOKING_UNLOCK_THRESHOLD_MINUTES,
  );
  const isUnlockLoading = unlockStatus === "pending";
  const isUnlockDisabled =
    !isUnlockAvailable || isUnlockLoading || !!booking.endedAt;
  const isExpired = !isAtMostNHoursAfter(
    booking.ends,
    BOOKING_UNLOCK_GRACE_PERIOD_HOURS,
  );

  const isAnyLockOperationInProgress =
    isEndBookingInProgress || isLockOperationInProgress || isUnlockLoading;
  const canOpenBluetoothLock = isUnlockAvailable && hasBluetoothLock;
  const canCloseBluetoothLock = Boolean(booking.startedAt) && hasBluetoothLock;

  const [, rerender] = useReducer((s) => !s, false);

  useEffect(() => {
    if (isUnlockAvailable) return;
    // TODO: is once a second really necessary? maybe speed it up once we reach 1min?
    const interval = setInterval(rerender, 1000);

    return () => clearInterval(interval);
  }, [isUnlockAvailable]);

  if (isExpired)
    return (
      <Flex direction="column" gap="4" minWidth="100%">
        <Callout.Root>
          <Callout.Icon>
            <InfoCircledIcon />
          </Callout.Icon>
          <Callout.Text>
            Ihre Buchung ist abgelaufen. Vielen Dank für die Nutzung unseres
            Services!
          </Callout.Text>
        </Callout.Root>
      </Flex>
    );

  if (hasBluetoothLock)
    return (
      <Flex direction="column" gap="4" minWidth="100%">
        <Flex justify="center">
          <UnlockBikeIcon className="unlock-bike-icon" aria-hidden />
        </Flex>

        <Text>
          Sie können Ihr{hasMultipleUnits ? "e Fahrräder" : " Fahrrad"}{" "}
          <Strong>
            {formatTimeUntil(booking.starts, BOOKING_UNLOCK_THRESHOLD_MINUTES)}
          </Strong>{" "}
          benutzen.
        </Text>

        <Blockquote>
          Gebucht für {formatDate(booking.starts)}, ab{" "}
          {formatTime(booking.starts)}
        </Blockquote>

        {booking.units.map((unit) => (
          <UnitCard
            key={unit.id}
            unit={unit}
            openLock={openBluetoothLock}
            closeLock={closeBluetoothLock}
            isUnlockAvailable={isUnlockAvailable}
            isUnlockDisabled={isUnlockDisabled}
            canOpenBluetoothLock={canOpenBluetoothLock}
            canCloseBluetoothLock={canCloseBluetoothLock}
            isLockOperationInProgress={isAnyLockOperationInProgress}
          />
        ))}

        <EndBooking
          getLockStatus={getLockStatus}
          isLockOperationInProgress={isAnyLockOperationInProgress}
          onEndBookingStateChange={setIsEndBookingInProgress}
        />
      </Flex>
    );

  return (
    <Flex direction="column" gap="4" minWidth="100%">
      <Flex justify="center">
        <UnlockBikeIcon className="unlock-bike-icon" aria-hidden />
      </Flex>

      <Text>
        Sie können Ihr{hasMultipleUnits ? "e Fahrräder" : " Fahrrad"}{" "}
        <Strong>
          {formatTimeUntil(booking.starts, BOOKING_UNLOCK_THRESHOLD_MINUTES)}
        </Strong>{" "}
        in der <Strong>{booking.pickupLocation}</Strong> abholen.
      </Text>

      <Blockquote>
        am {formatDate(booking.starts)}, von {formatTime(booking.starts)} bis{" "}
        {formatTime(booking.ends)}
      </Blockquote>

      {(hasDoorLock || includesUnitLocks) && (
        <Text>
          Elektronisch gesicherte Fahrräder und Türen können durch Klicken auf
          den unten stehenden Knopf geöffnet werden.
        </Text>
      )}

      {booking.units.some((unit) => unit.pin) && (
        <Text>
          Fahrräder mit Zahlenschloss können mit der PIN aufgeschlossen werden.
        </Text>
      )}

      {booking.units.map((unit) => (
        <UnitCard
          key={unit.id}
          unit={unit}
          isUnlockAvailable={isUnlockAvailable}
          isUnlockDisabled={isUnlockDisabled}
          openLock={openBluetoothLock}
          closeLock={closeBluetoothLock}
          canOpenBluetoothLock={canOpenBluetoothLock}
          canCloseBluetoothLock={canCloseBluetoothLock}
          isLockOperationInProgress={isAnyLockOperationInProgress}
        />
      ))}

      {booking.units.some((unit) => unit.hasLock) && (
        <Button
          disabled={isUnlockDisabled}
          onClick={() => unlock(booking.id)}
          loading={isUnlockLoading}
        >
          Eingangstür {includesUnitLocks ? "und Schlüsselkästen" : ""} öffnen
        </Button>
      )}
      {isUnlockSuccess && (
        <Callout.Root color="green">
          <Callout.Icon>
            <CheckCircledIcon />
          </Callout.Icon>
          <Callout.Text>
            Eingangstür {includesUnitLocks ? "und Schlüsselkästen" : ""}{" "}
            geöffnet, bitte nehmen Sie Ihr
            {hasMultipleUnits ? "e Fahrräder" : " Fahrrad"}.
          </Callout.Text>
        </Callout.Root>
      )}

      <EndBooking
        getLockStatus={getLockStatus}
        isLockOperationInProgress={isAnyLockOperationInProgress}
        onEndBookingStateChange={setIsEndBookingInProgress}
      />
    </Flex>
  );
}

function UnitCard({
  unit,
  isUnlockAvailable,
  isUnlockDisabled,
  openLock,
  closeLock,
  canOpenBluetoothLock,
  canCloseBluetoothLock,
  isLockOperationInProgress,
}: {
  unit: Unit;
  isUnlockAvailable: boolean;
  isUnlockDisabled: boolean;
  openLock: (deviceName: string) => Promise<void>;
  closeLock: (deviceName: string) => Promise<void>;
  canOpenBluetoothLock: boolean;
  canCloseBluetoothLock: boolean;
  isLockOperationInProgress: boolean;
}) {
  const { handleError } = useGenericErrorHandler();
  const [isOpening, setIsOpening] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleOpenLock = async () => {
    try {
      setIsOpening(true);
      await openLock(unit.id);
    } catch (error) {
      handleError(error, "Schloss konnte nicht geöffnet werden");
    } finally {
      setIsOpening(false);
    }
  };

  const handleCloseLock = async () => {
    try {
      setIsClosing(true);
      await closeLock(unit.id);
    } catch (error) {
      handleError(error, "Schloss konnte nicht geschlossen werden");
    } finally {
      setIsClosing(false);
    }
  };

  const disableBluetoothOpenButton =
    !canOpenBluetoothLock || isLockOperationInProgress || isUnlockDisabled;
  const disableBluetoothCloseButton =
    !canCloseBluetoothLock || isLockOperationInProgress || isUnlockDisabled;

  return (
    <Card size="1">
      <Flex gap="3" align="center">
        <Avatar
          className="unit"
          size="3"
          src={unit.imageUrl}
          radius="large"
          fallback={unit.name.charAt(0)}
        />
        <Box flexGrow="1">
          <Text as="div" size="2" weight="bold">
            {unit.name}
          </Text>
          <Text as="div" size="2" color="gray">
            {unit.description}
          </Text>
        </Box>

        <Box width="80px">
          <Flex direction="column" gap="0">
            {unit.pickupHint && <Text size="1">{unit.pickupHint}</Text>}
            {unit.pin && (
              <Text>PIN: {isUnlockAvailable ? unit.pin : "****"}</Text>
            )}
          </Flex>
        </Box>
      </Flex>
      {unit.hasBluetoothLock && (
        <Flex gap="2" mt="2">
          <Button
            style={{ flex: "1 1 0" }}
            onClick={() => void handleOpenLock()}
            disabled={disableBluetoothOpenButton}
            loading={isOpening}
          >
            Schloss öffnen
          </Button>
          <Button
            style={{ flex: "1 1 0" }}
            variant="soft"
            onClick={() => void handleCloseLock()}
            disabled={disableBluetoothCloseButton}
            loading={isClosing}
          >
            Schloss schließen
          </Button>
        </Flex>
      )}
    </Card>
  );
}
