import { Booking } from "use-smart-locks-shared";
import { useBookingQuery } from "../../shared/queries/use-booking-query";
import { useCampingBookingSlug } from "./use-camping-booking-slug";

export const useCampingBooking = (): {
  booking: Booking;
  hasMultipleUnits: boolean;
  hasBluetoothLock: boolean;
} => {
  const bookingSlug = useCampingBookingSlug();

  const { data } = useBookingQuery(bookingSlug);

  const hasMultipleUnits = data.units.length > 1;
  const hasBluetoothLock = data.units.some((unit) => unit.hasBluetoothLock);

  return { booking: data, hasMultipleUnits, hasBluetoothLock };
};
